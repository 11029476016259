import React from 'react';
import '../../App.js';
import Footer from '../Footer.js';

function Page(){
    return (
        <>
            <Footer/>
        </>
    )
}

export default Page;