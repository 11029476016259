import React from 'react';
import '../../App.js';
import Testimonials from '../Testimonials.js';

function Page(){
    return (
        <>
            <Testimonials/>
        </>
    )
}

export default Page;