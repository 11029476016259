import React from 'react';
import '../../App.js';
import Register from '../Register.js';

function page(){
    return (
        <>
            <Register/>
        </>
    )
}

export default page;