import React from 'react';
import '../../App.js';
import Front from '../Home.js';

function Page(){
    return (
        <>
            <Front/>
        </>
    )
}

export default Page;