import React from 'react';
import "../styles/Register.css";

function Register() {
    // const [click, setClick] = useState(false);
    // const handleClick = setClick(!click);
    return (
    <>
        <div id="register" className="Register" >
            <div>Ready to invest in your health?</div>
            <a href="https://docs.google.com/forms/d/1SF_C40shQ_PTPvsYqGiXJYwnlqs1juJm2Ili5v8eh6M/edit?ts=6677f5fb"><h1>Register Now!</h1></a>
            <div><b><em>Limited Seats available!</em></b></div>
        </div>
    
    </>
    );
}

export default Register;